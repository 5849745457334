import React, { Fragment, useEffect } from 'react';
import logo from '../../images/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import {useSelector , useDispatch} from 'react-redux';
import {DataGrid} from '@material-ui/data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';
import SuperSidebar from './SuperSidebar';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { allResultAction , clearErrors , deleteResultAction } from '../../actions/resultActions.js';
import { DELETE_RESULT_RESET } from '../../constants/resultsConstants.js';


const SuperAllResults = () => {

  const {error:userError,user} = useSelector((state)=>state.loginUser);

  const {error,loading,results} = useSelector((state)=>state.allResults);
  const {error:deleteError,message,success} = useSelector((state)=>state.createResult);



  const history = useNavigate();
  const alert = useAlert();


  const dispatch = useDispatch();

  const deleteSubmitHandler = (id) => {
    dispatch(deleteResultAction(id))
  };


  const columns = [
    {
      field:"id",
      headerName:"Result ID's",
      minWidth:250,
      flex:1
     },
     {
      field:"institute",
      headerName:"Institute Name",
      minWidth:250,
      flex:1
     },
     {
      field:"createdAt",
      headerName:"Created At",
      minWidth:250,
      flex:1
     },
    {
     field:"studentId",
     headerName:"Students ID's",
     minWidth:250,
     flex:2.5
    },
    {
      field:"studentName",
      headerName:"Students Names",
      minWidth:250,
      flex:2.5
     },
     {
      field:"studentFatherName",
      headerName:"Students Father CNIC/Passport Number",
      minWidth:250,
      flex:2.5
     },
     {
      field:"activityName",
      headerName:"Activity Name",
      minWidth:250,
      flex:2.5
     },
    {
      field:"activityNo",
      headerName:"Activity Number's",
      minWidth:250,
      flex:1
    },
   {
    field:"subject1Name",
    headerName:"Subject 1 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"subject1Grade",
    headerName:"Subject 1 Grades",
    minWidth:250,
    flex:1
  },
  {
    field:"subject1status",
    headerName:"Subject 1 Status's",
    minWidth:250,
    flex:1
  },
  {
    field:"subject2Name",
    headerName:"Subject 2 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"subject2Grade",
    headerName:"Subject 2 Grades",
    minWidth:250,
    flex:1
  },
  {
    field:"subject2status",
    headerName:"Subject 2 Status's",
    minWidth:250,
    flex:1
  },
  {
    field:"subject3Name",
    headerName:"Subject 3 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"subject3Grade",
    headerName:"Subject 3 Grades",
    minWidth:250,
    flex:1
  },
  {
    field:"subject3status",
    headerName:"Subject 3 Status's",
    minWidth:250,
    flex:1
  },
  {
    field:"subject4Name",
    headerName:"Subject 4 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"subject4Grade",
    headerName:"Subject 4 Grades",
    minWidth:250,
    flex:1
  },
  {
    field:"subject4status",
    headerName:"Subject 4 Status's",
    minWidth:250,
    flex:1
  },
  {
    field:"subject5Name",
    headerName:"Subject 5 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"subject5Grade",
    headerName:"Subject 5 Grades",
    minWidth:250,
    flex:1
  },
  {
    field:"subject5status",
    headerName:"Subject 5 Status's",
    minWidth:250,
    flex:1
  },
  {
    field:"teacherFeedback",
    headerName:"Teachers Feedbacks",
    minWidth:250,
    flex:1
  },

  {
    field:"actions",
    headerName:"Actions",
    flex:0.3,
    minWidth:200,
    sortable:false,
    renderCell:(params)=>{
      return (
        <Fragment>
       
          <Link to={`${params.getValue(params.id,"id")}`} >
            <EditIcon/>
          </Link>
          <Button onClick={()=>deleteSubmitHandler(`${params.getValue(params.id,"id")}`)}>
            <DeleteIcon />
          </Button>
        </Fragment>
      )
    }
  }
];

const rows = [];


results && results.map((res) => ( 
  rows.push({  
    id: res._id,
    studentId: res.studentId,
    activityNo: res.activityNo,
    subject1Name: res.subject1Name,
    subject1Grade: res.subject1Grade,
    subject1status: res.subject1status,
    subject2Name: res.subject2Name,
    subject2Grade: res.subject2Grade,
    subject2status: res.subject2status,
    subject3Name: res.subject3Name,
    subject3Grade: res.subject3Grade,
    subject3status: res.subject3status,
    subject4Name: res.subject4Name,
    subject4Grade: res.subject4Grade,
    subject4status: res.subject4status,
    subject5Name: res.subject5Name,
    subject5Grade: res.subject5Grade,
    subject5status: res.subject5status,
    teacherFeedback: res.teacherFeedback,
    studentName: res.studentName,
    studentFatherName: res.studentFatherName,
    createdAt: String(res.createdAt).substr(0,10),
    activityName: res.activityName,
    institute: res.institute,
  
})
))


  useEffect(()=>{
    if (userError) {
        alert.error(userError);
        dispatch(clearErrors());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success(message);
      history(`/super-admin-panel`)
      dispatch({
        type:DELETE_RESULT_RESET
      })
    }

    dispatch(allResultAction())
 
  },[userError,alert,dispatch,error,deleteError,history,success,message])
  return ( <Fragment>
    {
      loading ? (<Loader />):(
        
        <Fragment>
        <div className='dashboardMainContainer'>
          
      <div className="bannerContainer">
                <div>
                <img src={logo} alt="logo" />
                </div>     
                <div>
                  <b>{user && user.firstName} {user && user.lastName}</b>
                  <p><SearchIcon/></p>
                </div>     
                  </div>
          <div className="maindashboard">
       
              <div>
                  <SuperSidebar />
              </div>
              <div>
              <div className="productListContainer">
                <h1 id='productListHeading'>All Students Results Data Management</h1>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  disableSelectionOnClick
                  className='productListTable'
                  autoHeight
                />
              </div>
      
              </div>
          </div>
        </div>
      
      
      
      </Fragment>
      )
    }
  </Fragment>
            )
}
       

export default SuperAllResults