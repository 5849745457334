import React, { Fragment, useEffect } from 'react';
import logo from '../../images/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import {useSelector , useDispatch} from 'react-redux';
import { clearErrors } from '../../actions/userActions';
import AdminSidebar from './AdminSidebar';
import {DataGrid} from '@material-ui/data-grid';
import './AllDashboard.css';
import {  allSchoolsDashboardAction , deleteAdminDashboardAction } from '../../actions/dashboardActions.js';
import Loader from '../Loader/Loader.js';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@material-ui/core';
import { DELETE_DASHBOARD_RESET } from '../../constants/dashboardConstants.js';


const AllDashboard = () => {

  const {error:userError,user} = useSelector((state)=>state.loginUser);
  const {loading,error,dashboards} = useSelector((state)=>state.dashboard);
  const {error:deleteError,success,message} = useSelector((state)=>state.createDashboard);

  const alert = useAlert();

  const dispatch = useDispatch();

  const history = useNavigate();

  const deleteSubmitHandler = (id) => {
    dispatch(deleteAdminDashboardAction(id))
  }


  const columns = [
    {
     field:"id",
     headerName:"Subject ID's",
     minWidth:350,
     flex:2.5
    },
    {
      field:"institute",
      headerName:"Institute Name",
      minWidth:250,
      flex:2.5
     },
     {
      field:"classTeaching",
      headerName:"Class Grade",
      minWidth:250,
      flex:2.5
     },
   {
    field:"name",
    headerName:"Subject Names",
    minWidth:250,
    flex:1
  },
  {
    field:"createdAt",
    headerName:"Created At",
    minWidth:250,
    flex:1
  },
  {
    field:"activeTopic",
    headerName:"Subject Active Topics",
    minWidth:250,
    flex:1
  },
  {
    field:"skill1Name",
    headerName:"Skill 1 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"skill1Percentage",
    headerName:"Skill 1 Percentages",
    minWidth:250,
    flex:1
  },
  {
    field:"skill2Name",
    headerName:"Skill 2 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"skill2Percentage",
    headerName:"Skill 2 Percentages",
    minWidth:250,
    flex:1
  },
  {
    field:"skill3Name",
    headerName:"Skill 3 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"skill3Percentage",
    headerName:"Skill 3 Percentages",
    minWidth:250,
    flex:1
  },
  {
    field:"skill4Name",
    headerName:"Skill 4 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"skill4Percentage",
    headerName:"Skill 4 Percentages",
    minWidth:250,
    flex:1
  },
  {
    field:"skill5Name",
    headerName:"Skill 5 Names",
    minWidth:250,
    flex:1
  },
  {
    field:"skill5Percentage",
    headerName:"Skill 5 Percentages",
    minWidth:250,
    flex:1
  },
  {
    field:"downloadStandards",
    headerName:"Download Subject Standards",
    minWidth:250,
    flex:1
  },
  {
    field:"youtube",
    headerName:"Youtube Embed Links",
    minWidth:250,
    flex:1
  },

  {
    field:"actions",
    headerName:"Actions",
    flex:0.3,
    minWidth:200,
    sortable:false,
    renderCell:(params)=>{
      return (
        <Fragment>
       
          <Link to={`edit/${params.getValue(params.id,"id")}`} >
          <EditIcon />
          </Link>
          <Button onClick={()=>deleteSubmitHandler(params.getValue(params.id,"id"))}>
          <DeleteIcon />
          </Button>
        </Fragment>
      )
    }
  }
];


const rows = []; 


dashboards && dashboards.map((dash)=>(
  rows.push({
      id:dash._id,
      institute:dash.institute,
      classTeaching:dash.classTeaching,
      name:dash.name,
      activeTopic:dash.activeTopic,
      skill1Name:dash.skill1Name,
      skill1Percentage:dash.skill1Percentage,
      skill2Name:dash.skill2Name,
      skill2Percentage:dash.skill2Percentage,
      skill3Name:dash.skill3Name,
      skill3Percentage:dash.skill3Percentage,
      skill4Name:dash.skill4Name,
      skill4Percentage:dash.skill4Percentage,
      skill5Name:dash.skill5Name,
      skill5Percentage:dash.skill5Percentage,
      downloadStandards:dash.downloadStandards,
      youtube:dash.youtube,
      createdAt:String(dash.createdAt).substr(0,10),
  })
))



  useEffect(()=>{
    if (userError) {
        alert.error(userError);
        dispatch(clearErrors());
    }
    if (error) {
        alert.error(error);
        dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
  }
  if (success) {
    alert.success(message);
    history(`/admin-panel`)
    dispatch({
      type:DELETE_DASHBOARD_RESET
    })
    
  }
 
    dispatch(allSchoolsDashboardAction());
  },[userError,alert,dispatch,deleteError,success,message,error])
  return (
       <Fragment>
        {
            loading ? (<Loader />):(
                <Fragment>
                <div className='dashboardMainContainer'>
                  
              <div className="bannerContainer">
                        <div>
                        <img src={logo} alt="logo" />
                        </div>     
                        <div>
                          <b>{user && user.firstName} {user && user.lastName}</b>
                          <p><SearchIcon/></p>
                        </div>     
                          </div>
                  <div className="maindashboard">
               
                      <div>
                          <AdminSidebar />
                      </div>
                      <div>
                      <div className="productListContainer">
                        <h1 id='productListHeading'>All STEAM'S Dashboard Data Management</h1>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          pageSize={10}
                          disableSelectionOnClick
                          className='productListTable'
                          autoHeight
                        />
                      </div>
              
                      </div>
                  </div>
                </div>
              
              
              
              </Fragment>
            )
        }
       </Fragment>
    )
}

export default AllDashboard