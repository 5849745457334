import { CREATE_RESULT_FAIL, CREATE_RESULT_REQUEST, CREATE_RESULT_RESET, CREATE_RESULT_SUCCESS , CLEAR_ERRORS, ALL_RESULT_REQUEST, ALL_RESULT_SUCCESS, ALL_RESULT_FAIL, DELETE_RESULT_REQUEST, DELETE_RESULT_SUCCESS, DELETE_RESULT_FAIL, DELETE_RESULT_RESET, RESULT_DETAIL_REQUEST, RESULT_DETAIL_SUCCESS, RESULT_DETAIL_FAIL, UPDATE_RESULT_REQUEST, UPDATE_RESULT_SUCCESS, UPDATE_RESULT_FAIL, UPDATE_RESULT_RESET, STUDENT_RESULT_REQUEST, STUDENT_RESULT_SUCCESS, STUDENT_RESULT_FAIL, MY_RESULTS_REQUEST, MY_RESULTS_SUCCESS, MY_RESULTS_FAIL } from "../constants/resultsConstants";


// Create Result  -- Admin 
export const createResultReducer = (state = { result :{}} , action) => {
    switch (action.type) {
        case CREATE_RESULT_REQUEST:
            case DELETE_RESULT_REQUEST:
                case UPDATE_RESULT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case CREATE_RESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.payload.result,
                success: action.payload.success,
               
            }    
        case DELETE_RESULT_SUCCESS:
            case UPDATE_RESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
                message: action.payload.message
            }    
        case CREATE_RESULT_FAIL:
            case DELETE_RESULT_FAIL:
                case UPDATE_RESULT_FAIL:
                
            return {
                ...state,
                loading: false,
                error: action.payload,
            }  
        case CREATE_RESULT_RESET:

            return {
                ...state,
                success:false,
                result:null
            }    
        case DELETE_RESULT_RESET:
            case UPDATE_RESULT_RESET:
            return {
                ...state,
                message:null,
                success:false,
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }      
    
        default:
            return state;
    }
};


// All Result 
export const allResultReducer = (state = { results :[]},action) => {
    switch (action.type) {
        case ALL_RESULT_REQUEST:
            case STUDENT_RESULT_REQUEST:
                case MY_RESULTS_REQUEST:
            return {
                loading: true,
                results: [],
            }
        case ALL_RESULT_SUCCESS:
            case STUDENT_RESULT_SUCCESS:
                case MY_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                results: action.payload.results,

            }   
        case ALL_RESULT_FAIL:
            case STUDENT_RESULT_FAIL:
                case MY_RESULTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }     
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }    
    
        default:
            return state;
    }
}



// Result Details Reducer
export const ResultDetailsReducer = (state  = { result: {}}, action) => {
    switch (action.type) {
        case RESULT_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case RESULT_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.payload.result,
            }    
        case RESULT_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }   
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }     
    
        default:
            return state;
    }
}