import React, { Fragment, useEffect } from 'react';
import logo from '../../images/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import {useSelector , useDispatch} from 'react-redux';
import AdminSidebar from './AdminSidebar';
import {DataGrid} from '@material-ui/data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './AllDashboard.css';
import { allClassLessonActions , clearErrors , deleteLessonAction} from '../../actions/lessonAction';
import Loader from '../Loader/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { DELETE_LESSON_RESET } from '../../constants/lessonConstants';
import { Button } from '@mui/material'; 

const AllLessons = () => {

  const {error:userError,user} = useSelector((state)=>state.loginUser);

  const {error,loading,lesson} = useSelector((state)=>state.allLessons);

    const {error:deleteError,message,success} = useSelector((state)=>state.deleteLesson);
  

  const deleteSubmitHandler = (id) => {
    dispatch(deleteLessonAction(id))
  };

  const alert = useAlert();

  const history = useNavigate();


  const dispatch = useDispatch();

  const columns = [
    {
     field:"id",
     headerName:"Lessons ID's",
     minWidth:300,
     flex:1
    },
    {
      field:"institute",
      headerName:"Institute Names",
      minWidth:300,
      flex:1
     },
     {
      field:"classTeaching",
      headerName:"Class Grade",
      minWidth:300,
      flex:1
     },
   {
    field:"name",
    headerName:"Lessons Names",
    minWidth:300,
    flex:1
  },
  {
    field:"createdAt",
    headerName:"Lessons Created At",
    minWidth:300,
    flex:1
  },
  {
    field:"actions",
    headerName:"Actions",
    flex:1,
    minWidth:200,
    sortable:false,
    renderCell:(params)=>{
      return (
        <Fragment>
       
          <Link to={`${params.getValue(params.id,"id")}`} >
            <EditIcon/>
          </Link>
          <Button onClick={()=>deleteSubmitHandler(`${params.getValue(params.id,"id")}`)}>
            <DeleteIcon />
          </Button>
        </Fragment>
      )
    }
  }
];

const rows = [];

lesson && lesson.map((less) =>(
  rows.push({
    id: less._id,
    name: less.name,
    createdAt: String(less.createdAt).substr(0,10),
    institute: less.institute || "N/A",
    classTeaching: less.classTeaching || "N/A",

  })
))


  useEffect(()=>{
    if (userError) {
        alert.error(userError);
        dispatch(clearErrors());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
     if (deleteError) {
          alert.error(deleteError);
          dispatch(clearErrors());
        }
        if (success) {
          alert.success(message);
          history(`/admin-panel`)
          dispatch({
            type:DELETE_LESSON_RESET
          })
        }

    dispatch(allClassLessonActions())
 
  },[userError,alert,dispatch,error,message,success,history,deleteError])
  return ( <Fragment>
    {
      loading ? (<Loader />):(
        
        <Fragment>
        <div className='dashboardMainContainer'>
          
      <div className="bannerContainer">
                <div>
                <img src={logo} alt="logo" />
                </div>     
                <div>
                  <b>{user && user.firstName} {user && user.lastName}</b>
                  <p><SearchIcon/></p>
                </div>     
                  </div>
          <div className="maindashboard">
       
              <div>
                  <AdminSidebar />
              </div>
              <div>
              <div className="productListContainer">
                <h1 id='productListHeading'>All Lessons Data Management</h1>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  disableSelectionOnClick
                  className='productListTable'
                  autoHeight
                />
              </div>
      
              </div>
          </div>
        </div>
      
      
      
      </Fragment>
      )
    }
  </Fragment>
            )
}
       

export default AllLessons