import { CREATE_RESULT_FAIL, CREATE_RESULT_REQUEST, CREATE_RESULT_SUCCESS , CLEAR_ERRORS, ALL_RESULT_FAIL, ALL_RESULT_REQUEST, ALL_RESULT_SUCCESS, DELETE_RESULT_FAIL, DELETE_RESULT_REQUEST, DELETE_RESULT_SUCCESS, RESULT_DETAIL_FAIL, RESULT_DETAIL_REQUEST, RESULT_DETAIL_SUCCESS, UPDATE_RESULT_FAIL, UPDATE_RESULT_REQUEST, UPDATE_RESULT_SUCCESS, STUDENT_RESULT_FAIL, STUDENT_RESULT_REQUEST, STUDENT_RESULT_SUCCESS, MY_RESULTS_FAIL, MY_RESULTS_REQUEST, MY_RESULTS_SUCCESS } from "../constants/resultsConstants";

import axios from 'axios';


// Create Result Action
export const createResultAction = (newData) => async (dispatch ) => {
    try {
        dispatch({type: CREATE_RESULT_REQUEST});

        const config = {headers:{'Content-Type': 'multipart/form-data'}}

        const {data} = await axios.post(`/api/v1/result/create`,newData,config);

        dispatch({
            type: CREATE_RESULT_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
}


// ALL Result Action
export const allResultAction = () => async (dispatch) => {
    try {
        dispatch({type: ALL_RESULT_REQUEST});

        const {data} = await axios.get('/api/v1/results');

        dispatch({
            type: ALL_RESULT_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type:ALL_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
};




// ALL Result Action  -- Admin
export const allSchoolResultAction = () => async (dispatch) => {
    try {
        dispatch({type: ALL_RESULT_REQUEST});

        const {data} = await axios.get('/api/v1/school/results');

        dispatch({
            type: ALL_RESULT_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type:ALL_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
};


// Delete Result 
export const deleteResultAction = (id) => async (dispatch) => {
    try {
        dispatch({type: DELETE_RESULT_REQUEST});

        const config = {headers: {"Content-Type": "multipart/form-data"}};

        const {data} = await axios.delete(`/api/v1/result/${id}`,config);

        dispatch({
            type: DELETE_RESULT_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: DELETE_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Get Result Details
export const getResultDetails = (id) => async (dispatch) => {
    try {
        dispatch({type: RESULT_DETAIL_REQUEST});

        const {data} = await axios.get(`/api/v1/result/${id}`);

        dispatch({
            type: RESULT_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type:RESULT_DETAIL_FAIL,
            payload: error.response.data.message,
        })
    }
}

// Update Result 
export const updateResultAction = (id,newResultData) => async (dispatch) => {
    try {
        dispatch({type: UPDATE_RESULT_REQUEST});

        const config = {headers: {'Content-Type': 'multipart/form-data'}};

        const {data} = await axios.put(`/api/v1/result/${id}`,newResultData,config);

        dispatch({
            type: UPDATE_RESULT_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Student All Results  -- Student
export const studentResultAction = () => async (dispatch) => {
    try {
        dispatch({type: STUDENT_RESULT_REQUEST});
        
        const {data} = await axios.get('/api/v1/result');

        dispatch({
            type: STUDENT_RESULT_SUCCESS,
            payload: data
 
        })
    } catch (error) {
        dispatch({
            type: STUDENT_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
};


// My Students Results  -- Teacher
export const myStudentResultsAction = () => async (dispatch) => {
    try {
        dispatch({type: MY_RESULTS_REQUEST});

        const {data} = await axios.get('/api/v1/me/results');

        dispatch({
            type: MY_RESULTS_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type:MY_RESULTS_FAIL,
            payload: error.response.data.message
        })
    }
}


// Clear Errors Action
export const clearErrors = () => async (dispatch) => {
    dispatch({type:CLEAR_ERRORS})
}