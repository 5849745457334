export const CREATE_RESULT_REQUEST = 'CREATE_RESULT_REQUEST';
export const CREATE_RESULT_SUCCESS = 'CREATE_RESULT_SUCCESS';
export const CREATE_RESULT_FAIL = 'CREATE_RESULT_FAIL';
export const CREATE_RESULT_RESET = 'CREATE_RESULT_RESET';

export const ALL_RESULT_REQUEST = 'ALL_RESULT_REQUEST';
export const ALL_RESULT_SUCCESS = 'ALL_RESULT_SUCCESS';
export const ALL_RESULT_FAIL = 'ALL_RESULT_FAIL';

export const DELETE_RESULT_REQUEST = 'DELETE_RESULT_REQUEST';
export const DELETE_RESULT_SUCCESS = 'DELETE_RESULT_SUCCESS';
export const DELETE_RESULT_FAIL = 'DELETE_RESULT_FAIL';
export const DELETE_RESULT_RESET = 'DELETE_RESULT_RESET';

export const RESULT_DETAIL_REQUEST = 'RESULT_DETAIL_REQUEST';
export const RESULT_DETAIL_SUCCESS = 'RESULT_DETAIL_SUCCESS';
export const RESULT_DETAIL_FAIL = 'RESULT_DETAIL_FAIL';

export const UPDATE_RESULT_REQUEST = 'UPDATE_RESULT_REQUEST';
export const UPDATE_RESULT_SUCCESS = 'UPDATE_RESULT_SUCCESS';
export const UPDATE_RESULT_FAIL = 'UPDATE_RESULT_FAIL';
export const UPDATE_RESULT_RESET = 'UPDATE_RESULT_RESET';

export const STUDENT_RESULT_REQUEST = 'STUDENT_RESULT_REQUEST';
export const STUDENT_RESULT_SUCCESS = 'STUDENT_RESULT_SUCCESS';
export const STUDENT_RESULT_FAIL = 'STUDENT_RESULT_FAIL';

export const MY_RESULTS_REQUEST = 'MY_RESULTS_REQUEST';
export const MY_RESULTS_SUCCESS = 'MY_RESULTS_SUCESS';
export const MY_RESULTS_FAIL = 'MY_RESULTS_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';