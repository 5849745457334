import React, { Fragment, useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import { useSelector , useDispatch } from 'react-redux';
import logo from '../../images/logo.png';
import { clearErrors  } from '../../actions/userActions';
import { updateResultAction , getResultDetails } from '../../actions/resultActions';
import {  UPDATE_RESULT_RESET } from '../../constants/resultsConstants';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import TeacherSidebar from './TeacherSidebar';


const TeacherUpdateResult = () => {


  const {error:userError,user} = useSelector((state)=>state.loginUser);
  const {error,loading,success} = useSelector((state)=>state.createResult);
  const {error:detailError,result} = useSelector((state)=>state.resultDetail)
  const alert = useAlert();
  const dispatch = useDispatch();
  const {id} = useParams();

  const [activityNo , setActivityNo  ] = useState(0);
  const [teacherFeedback , setTeacherFeedback  ] = useState("");
  const [studentId , setStudentId  ] = useState("");
  const [studentName , setStudentName  ] = useState("");
  const [studentFatherName , setStudentFatherName  ] = useState("");

  const [activityName , setActivityName  ] = useState("");
  

  const history = useNavigate();



  const [subject1Name , setSubject1Name  ] = useState("");
  const [subject2Name , setSubject2Name  ] = useState("");
  const [subject3Name , setSubject3Name  ] = useState("");
  const [subject4Name , setSubject4Name  ] = useState("");
  const [subject5Name , setSubject5Name  ] = useState("");



  const [subject1status , setSubject1Status ] = useState(0);
  const [subject2status , setSubject2status ] = useState(0);
  const [subject3status , setSubject3status ] = useState(0);
  const [subject4status , setSubject4status ] = useState(0);
  const [subject5status , setSubject5status ] = useState(0);


  const [subject1Grade , setSubjectGrade ] = useState("");
  const [subject2Grade , setSubject2Grade ] = useState("");
  const [subject3Grade , setSubject3Grade ] = useState("");
  const [subject4Grade , setSubject4Grade ] = useState("");
  const [subject5Grade , setSubject5Grade ] = useState("");





  const setSubject1StatusChanger  = (value) => {
    if (value < 1) {
      setSubject1Status(1)
    } else if (value > 5) {
      setSubject1Status(5)
    }
    else {
      setSubject1Status(value)
    }
  }

  const setSubject2StatusChanger  = (value) => {
    if (value < 1) {
      setSubject2status(1)
    } else if (value > 5) {
      setSubject2status(5)
    }
    else {
      setSubject2status(value)
    }
  }
  const setSubject3StatusChanger  = (value) => {
    if (value < 1) {
      setSubject3status(1)
    } else if (value > 5) {
      setSubject3status(5)
    }
    else {
      setSubject3status(value)
    }
  }
  const setSubject4StatusChanger  = (value) => {
    if (value < 1) {
      setSubject4status(1)
    } else if (value > 5) {
      setSubject4status(5)
    }
    else {
      setSubject4status(value)
    }
  }
  const setActivityNoChanger  = (value) => {
    if (value < 1) {
      setActivityNo(1)
    } 
    else {
      setActivityNo(value)
    }
  }

  const setSubject5StatusChanger  = (value) => {
    if (value < 1) {
      setSubject5status(1)
    } else if (value > 5) {
      setSubject5status(5)
    }
    else {
      setSubject5status(value)
    }
  }


  const resultSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set('activityNo',activityNo);
    myForm.set('teacherFeedback',teacherFeedback);
    myForm.set('studentId',studentId);
    myForm.set('subject1Name',subject1Name);
    myForm.set('subject2Name',subject2Name);
    myForm.set('subject3Name',subject3Name);
    myForm.set('subject4Name',subject4Name);
    myForm.set('subject5Name',subject5Name);
    myForm.set('subject1status',subject1status);
    myForm.set('subject2status',subject2status);
    myForm.set('subject3status',subject3status);
    myForm.set('subject4status',subject4status);
    myForm.set('subject5status',subject5status);
    myForm.set('subject1Grade',subject1Grade);
    myForm.set('subject2Grade',subject2Grade);
    myForm.set('subject3Grade',subject3Grade);
    myForm.set('subject4Grade',subject4Grade);
    myForm.set('subject5Grade',subject5Grade);
    myForm.set('studentName',studentName);
    myForm.set('studentFatherName',studentFatherName);
    myForm.set('activityName',activityName);

 
    dispatch(updateResultAction(id,myForm));
  };



  useEffect(()=>{
    if (userError) {
      alert.error(userError)
      dispatch(clearErrors())   
    }
   if (error) {
    alert.error(error)
    dispatch(clearErrors())
   }
   if (detailError) {
    alert.error(detailError)
    dispatch(clearErrors())
   }
   if (success) {
    alert.success(`Result Updated Successfully`)
    history(`/teacher-panel`)
    dispatch({type: UPDATE_RESULT_RESET});
   }

   
  


    if (result && result._id !== id) {
           dispatch(getResultDetails(id));
               
           } else {
             setActivityNo(result.activityNo);
             setStudentId(result.studentId);
             setTeacherFeedback(result.teacherFeedback);
             setSubject1Name(result.subject1Name);
             setSubject2Name(result.subject2Name);
             setSubject3Name(result.subject3Name);
             setSubject4Name(result.subject4Name);
             setSubject5Name(result.subject5Name);
             setSubject1Status(result.subject1status);
             setSubject2status(result.subject2status);
             setSubject3status(result.subject3status);
             setSubject4status(result.subject4status);
             setSubject5status(result.subject5status);
             setSubjectGrade(result.subject1Grade);
             setSubject2Grade(result.subject2Grade);
             setSubject3Grade(result.subject3Grade);
             setSubject4Grade(result.subject4Grade);
             setSubject5Grade(result.subject5Grade);
             setStudentName(result.studentName);
             setStudentFatherName(result.studentFatherName);
             setActivityName(result.activityName);
           }
   
  
  },[userError,dispatch,alert,error,history,success , detailError , id , result]);
  return (
              <Fragment>
                {
                  loading ? (<Loader />):(
                    <Fragment>
              <div className='dashboardMainContainer'>
                
            <div className="bannerContainer">
                      <div>
                      <img src={logo} alt="logo" />
                      </div>     
                      <div>
                        <b>{user && user.firstName} {user && user.lastName}</b>
                        <p><SearchIcon/></p>
                      </div>     
                        </div>
                <div className=" createdashboard">
             
                    <div>
                        <TeacherSidebar />
                    </div>
                    <div>

                    <div className="createDashboardContainer">
                       <div className="createDashboardContainerForm">
                    <h1>Update Students Result </h1>
                    <form className='dashboardForm' onSubmit={resultSubmitHandler}>


                    <div className='dashboardInputs'>
                      <label className='dashInput'>

<input type="text"  className="input" value={studentName} onChange={(e) =>setStudentName(e.target.value)}

  />  
                      <i>Student Name</i>

                      
                      </label>
                      <label  className='dashInput' >

                        <input type="text"  className="input" value={studentFatherName} onChange={(e) => setStudentFatherName(e.target.value)}
                          />
                      <i>Father CNIC Number</i>

                      </label>
                      <label className='dashInput'>
                        <input type="Number"  className="input"  value={activityNo} onChange={(e) => setActivityNoChanger(e.target.value)}    />
                      <i>Activity</i>

                      </label>

                      </div>
                      <div className='dashboardInputs'>
                      <label className='dashInput'>

<input type="text"  className="input" value={subject1Name} onChange={(e) =>setSubject1Name(e.target.value)}

  />  
                      <i>Subject 1 Name</i>

                      
                      </label>
                      <label  className='dashInput' >

                        <input type="text"  className="input" value={subject1Grade} onChange={(e) => setSubjectGrade(e.target.value)}
                          />
                      <i>Subject 1 Grade</i>

                      </label>
                      <label  className='dashInput'>
                        <input type="Number"  className="input" value={subject1status} onChange={(e) => setSubject1StatusChanger(e.target.value)} />
                      <i>Status</i>

                      </label>

                      </div>
      

                      <div className='dashboardInputs'>
                      <label  className='dashInput'>


<input type="text"  className="input" value={subject2Name} onChange={(e) => setSubject2Name(e.target.value)} />
<i>Subject 2 Name</i>
</label>

<label  className='dashInput'>
                        <input type="text"  className="input" 
                      value={subject2Grade} onChange={(e) => setSubject2Grade(e.target.value)}
                        />
                      <i>Subject 2 Grade</i>

                      </label>
                      <label  className='dashInput'>
                        <input type="Number"  className="input" value={subject2status} onChange={(e) => setSubject2StatusChanger(e.target.value)}   />
                      <i>Status</i>

                      </label>

                        </div>



                        <div className='dashboardInputs'>
                        <label  className='dashInput'>
                        <input type="text"  className="input" value={subject3Name} onChange={(e) => setSubject3Name(e.target.value)}       />
                      <i>Subject 3 Name</i>
                      </label>

                      <label  className='dashInput'>
                        <input type="text" className="input"  value={subject3Grade} onChange={(e) => setSubject3Grade(e.target.value)}    
                         />
                      <i>Subject 3 Grade</i>


                      </label>
                      <label className='dashInput'>
                        <input type="Number"  className="input" value={subject3status} onChange={(e) => setSubject3StatusChanger(e.target.value)}      />
                      <i>Status</i>

                      </label>

                        </div>



                        <div className='dashboardInputs'>
                        <label className='dashInput'>
                        <input type="text"  className="input" 
                         value={subject4Name} onChange={(e) => setSubject4Name(e.target.value)} 
                         />
                         <i>Subject 4 Name</i>
                      </label>
                      <label className='dashInput'>
                        <input type="text"  className="input"   value={subject4Grade} onChange={(e) => setSubject4Grade(e.target.value)}    />
                         <i>Subject 4 Grade</i>
                      </label>
                      <label className='dashInput'>
                        <input type="Number"  className="input"  value={subject4status} onChange={(e) => setSubject4StatusChanger(e.target.value)}     />
                      <i>Status</i>

                      </label>
                         </div>

                         <div className='dashboardInputs'>
                        <label className='dashInput'>
                        <input type="text"  className="input" value={subject5Name} onChange={(e) => setSubject5Name(e.target.value)}
                         />
                         <i>Subject 5 Name</i>
                      </label>
                      <label className='dashInput'>
                        <input type="text"  className="input"  value={subject5Grade} onChange={(e) => setSubject5Grade(e.target.value)}  />
                         <i>Subject 5 Grade</i>
                      </label>
                      <label className='dashInput'>
                        <input type="Number"  className="input"  value={subject5status} onChange={(e) => setSubject5StatusChanger(e.target.value)}   />
                      <i>Status</i>

                      </label>
                         </div>

                         <div className='dashboardInputs'>
                         <label className='dashInput'>
                        <input type="text"  className="input"   value={activityName} onChange={(e) => setActivityName(e.target.value)} 
                         />
                         <i>Activity Name</i>
                      </label>
                         <label className='dashInput'>
                        <input type="text"  className="input"   value={teacherFeedback} onChange={(e) => setTeacherFeedback(e.target.value)} 
                         />
                         <i>Teacher feedback</i>
                      </label>
                        <label className='dashInput'>
                        <input type="text"  className="input" 
                        value={studentId} onChange={(e) => setStudentId(e.target.value)} 
                         />
                         <i>Student ID</i>
                      </label>
                    
    
                         </div>
                     
                     
     
                   
                     
                      <button type="submit" className="buttonBtn">Confirm  
                      </button>
                    </form>
                  </div>
                </div>
                    </div> 



                     
                    </div>
                </div>
            
            
            
            </Fragment>
                  )
                }
              </Fragment>
            )
          }
       

export default TeacherUpdateResult
