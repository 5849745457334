import { CHILDERN_PROFILES_FAIL, CHILDERN_PROFILES_REQUEST, CHILDERN_PROFILES_SUCCESS, CHILDERN_RESULTS_FAIL, CHILDERN_RESULTS_REQUEST, CHILDERN_RESULTS_SUCCESS, CLEAR_ERRORS } from "../constants/parentsConstants";

import axios from 'axios';

export const getChildrenProfilesAction = () => async (dispatch) => {
    try {
        dispatch({type: CHILDERN_PROFILES_REQUEST});

        const {data} = await axios.get('/api/v1/children-profiles');

        dispatch({
            type: CHILDERN_PROFILES_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type:CHILDERN_PROFILES_FAIL,
            payload: error.response.data.message
        })
    }
};


// Get Childern Results Actions  -- Parent
export const getChildernResultsAction = () => async (dispatch) => {
    try {
        dispatch({type:CHILDERN_RESULTS_REQUEST});

        const {data} = await axios.get('/api/v1/children-results');

        dispatch({
            type: CHILDERN_RESULTS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type:CHILDERN_RESULTS_FAIL,
            payload: error.response.data.message
        })
    }
};


// Clear Errors 
export const clearErrors = () => async (dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}
