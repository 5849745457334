import { Rate } from 'antd';
import React, { Fragment, useEffect } from 'react'
import Sidebar from '../Dashboard/Sidebar';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import { useSelector , useDispatch } from 'react-redux';
import logo from '../../images/logo.png';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Loader from '../Loader/Loader';
import { clearErrors , getChildernResultsAction } from '../../actions/parentActions';
import { Link } from 'react-router-dom';

const ChildernResults = () => {

  const emojiIcons = [
    '😔', // 1 star
    '😐', // 2 stars
    '🙂', // 3 stars
    '🤩', // 4 stars
    '🥳', // 5 stars
  ];

  const {error:userError,user} = useSelector((state)=>state.loginUser);
  const {loading , error , parents} = useSelector((state)=>state.childrenProfiles);
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(()=>{
    if (userError) {
      alert.error(userError)
      dispatch(clearErrors())
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getChildernResultsAction());
   
  },[userError,dispatch,alert,error])
  return (
          <Fragment>
            {
              loading ? (<Loader />):(
                <Fragment>
                <div className='dashboardMainContainer'>
                  
              <div className="bannerContainer">
                        <div>
                        <img src={logo} alt="logo" />
                        </div>     
                        <div>
                          <b>{user && user.firstName} {user && user.lastName}</b>
                          <p><SearchIcon/></p>
                        </div>     
                          </div>
                  <div className="maindashboard">
               
                      <div>
                          <Sidebar />
                      </div>
                      <div>
                       
                       {
                        parents && parents.map((result) => (
                          <div className='resultMainContainer'>
                          <div className="resultContainer1">
                    <div>
                     <div>
                     <LeaderboardIcon />
                     </div>
                     <div>
                      <h3> </h3>
                    <h3> {result && result.studentName}  {result && result.activityName}  Remarks </h3>
                    <p>Activity#{result && result.activityNo}</p>
       
                     </div>
                    
                    </div>     
                    <div>
                      <p><MoreHorizIcon/></p>
                    </div>     
                  </div>
           
       
                  <div className='resultContainer2'>
       
                   
                 
                  <div className="remarks-container">
            
       
             <div className="remarks-content">
               <table className="remarks-table">
                 <thead>
                   <tr>
                     <th>Label</th>
                     <th>Grades</th>
                     <th>Status</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td><span className="dot dot-blue"></span>{result && result.subject1Name}</td>
                     <td>{result && result.subject1Grade}</td>
                     <td>
                       <Rate
                         character={({ index }) => emojiIcons[index]}
                         count={5}
                         value={result && result.subject1status}
                         disabled
                       />
                     </td>
                   </tr>
                   <tr>
                     <td><span className="dot dot-red"></span>{result && result.subject2Name}</td>
                     <td>{result && result.subject2Grade}</td>
                     <td>
                       <Rate
                         character={({ index }) => emojiIcons[index]}
                         count={5}
                         value={result && result.subject2status}
                         disabled
                       />
                     </td>
                   </tr>
                   <tr>
                     <td><span className="dot dot-orange"></span>{result && result.subject3Name}</td>
                     <td>{result && result.subject3Grade}</td>
                     <td>
                       <Rate
                         character={({ index }) => emojiIcons[index]}
                         count={5}
                         value={result && result.subject3status}
                         disabled
                       />
                     </td>
                   </tr>
                   <tr>
                     <td><span className="dot dot-green"></span>{result && result.subject4Name}</td>
                     <td>{result && result.subject4Grade}</td>
                     <td>
                       <Rate
                         character={({ index }) => emojiIcons[index]}
                         count={5}
                         value={result && result.subject4status}
                         disabled
                       />
                     </td>
                   </tr>
                   <tr>
                     <td><span className="dot dot-light-orange"></span>{result && result.subject5Name}</td>
                     <td>{result && result.subject5Grade}</td>
                     <td>
                       <Rate
                         character={({ index }) => emojiIcons[index]}
                         count={5}
                         value={result && result.subject5status}
                         disabled
                       />
                     </td>
                   </tr>
                 </tbody>
       
               </table>
        <div className='downloadContainer'>
                      <Link to={`/result/download/${result._id}`} className="download-button">Proceed To Download Full Remarks</Link>
       
                      </div> 
               <div className="remarks-footer">
               <div>
                 <AccessTimeIcon />
               <p>{result && String(result.createdAt).substr(0,10)}</p>
       
               </div>  
              <div>
               <ChatBubbleOutlineIcon />
               <p>3</p>
        
             </div>      
             </div>
             </div>
       
           
       
             <div className="teacher-remarks">
               <h4>Teacher’s Remarks</h4>
               <textarea
                 className="remarks-textarea"
                 readOnly
                 value={result && result.teacherFeedback}
               />
               <div className="remarks-actions">
                  <div>
                  <AccountCircleIcon />
                 <AccountCircleIcon />
                 <AccountCircleIcon />
                  </div>
                  <div>
                   
                 <button className="icon-button">📎</button>
                 <button className="icon-button">😊</button>
                  </div>
               </div>
             </div>
           </div>
              
       
               
                     </div>
                 </div>
                        ))
                       }
              </div>
            
            
              </div>
               </div>
            
            </Fragment>
              )
            }
          </Fragment>
      )
}

export default ChildernResults 
