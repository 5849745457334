import React, { Fragment, useEffect } from 'react';
import logo from '../../images/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import {useSelector , useDispatch} from 'react-redux';
import { clearErrors } from '../../actions/userActions';
import { Link } from 'react-router-dom';
import MetaData from '../MetaData/MetaData';
import TeacherSidebar from './TeacherSidebar';

import {   myClassAllMembers   } from '../../actions/userActions';
import { allLessonActions   } from '../../actions/lessonAction';
import { allDashboardAction } from '../../actions/dashboardActions.js';


const TeacherPanel = () => {

  const {error:userError,user} = useSelector((state)=>state.loginUser);
  const {error,users} = useSelector((state)=>state.allUsers);
  const {error:dashboardError,dashboards} = useSelector((state)=>state.dashboard);
  const {error:lessonError,lesson} = useSelector((state)=>state.allLessons);

  const total = users.reduce((acc) => acc + 1, 0);

  const dashboard = dashboards.reduce((acc) => acc + 1, 0);

  const les = lesson.reduce((acc) => acc + 1, 0);


  const alert = useAlert();

  const dispatch = useDispatch();


  useEffect(()=>{
    if (userError) {
        alert.error(userError);
        dispatch(clearErrors());
    }
       if (dashboardError) {
              alert.error(dashboardError);
              dispatch(clearErrors());
            }
            if (lessonError) {
              alert.error(lessonError);
              dispatch(clearErrors());
            }
               if (error) {
                      alert.error(error);
                      dispatch(clearErrors());
                    }
    
                dispatch(myClassAllMembers())
                dispatch(allDashboardAction());
                dispatch(allLessonActions()) 
  },[userError,alert,dispatch,lessonError,error])
  return (
    <Fragment>
      <MetaData title={`TLH - Teacher Panel`} />
    <div className='dashboardMainContainer'>
      
  <div className="bannerContainer">
            <div>
            <img src={logo} alt="logo" />
            </div>     
            <div>
              <b>{user && user.firstName} {user && user.lastName}</b>
              <p><SearchIcon/></p>
            </div>     
              </div>
      <div className="maindashboard">
   
          <div>
              <TeacherSidebar />
          </div>
          <div>
           
          <div className='dashboardContainer' >
               <div className="dashboardSummary">
                 <div>
                 <p>
                 <h1>Wellcome</h1>    Teacher Panel </p>
                 </div>
                 <div className="dashboardSummaryBox2">
                   <Link>
                   <p>Member's</p>
                   <p>{total}</p>
                   </Link>
                   <Link  >
                     <p> STEAM'S</p>
                     <p>{dashboard}</p>
                   </Link>
                   <Link >
                     <p>Lesson's</p>
                     <p>{les}</p>
                   </Link>
                 </div>
               </div>
            
               </div>
  
          </div>
      </div>
    </div>
  
  
  
  </Fragment>
    )
}

export default TeacherPanel
