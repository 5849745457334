import jsPDF from "jspdf";
import {  Rate } from 'antd';
import './RemarksComponent.css';
import React, { Fragment, useEffect } from 'react'
import Sidebar from '../Dashboard/Sidebar';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import { useSelector , useDispatch } from 'react-redux';
import logo from '../../images/logo.png';
import { clearErrors  } from '../../actions/userActions';
import './Result.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { getResultDetails } from '../../actions/resultActions';
import Loader from '../Loader/Loader';
import "jspdf-autotable";
import {  useParams } from 'react-router-dom';


const DownloadResult = () => {

    const {id} = useParams();
    
      const emojiIcons = [
        '😔', // 1 star
        '😐', // 2 stars
        '🙂', // 3 stars
        '🤩', // 4 stars
        '🥳', // 5 stars
      ];
    
    
      const {error:userError,user} = useSelector((state)=>state.loginUser);
     const {error,result , loading} = useSelector((state)=>state.resultDetail);
      const alert = useAlert();
      const dispatch = useDispatch();

      

      const generatePDF = () => {
        const doc = new jsPDF();
      
        // Add school logo and header
        doc.addImage(logo, 'JPEG', 8, 8, 25, 25);
        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text(`${user.institute}`, 105, 20, { align: "center" }); // Centered Header
      
        doc.setFontSize(12);
        doc.setTextColor(100, 100, 100);
        doc.text(`${user.curriculumTeaching}`, 105, 28, { align: "center" }); // Centered Sub-header
      
        // Add a decorative line separator
        doc.setDrawColor(0, 102, 204);
        doc.setLineWidth(1);
        doc.line(10, 35, 200, 35);
      
        // Student & Activity Details Table
        const details = [
          ["Activity Name", result.activityName || "N/A"],
          ["Student Name", result.studentName || "N/A"],
          ["Grade Section", user.classTeaching || "N/A"],
        ];
      
        doc.autoTable({
          startY: 40,
          head: [["Details", ""]],
          body: details,
          theme: "grid",
          headStyles: {
            fillColor: [0, 102, 204],
            textColor: [255, 255, 255],
            fontSize: 10,
            halign: "left", // Center table headers
            fontStyle: "bold",
          },
          bodyStyles: {
            fontSize: 10,
          },
          styles: { cellPadding: 4 },
          columnStyles: {
            0: { halign: "left", fontStyle: "bold", cellWidth: 60 },
            1: { halign: "left", cellWidth: 130 },
          },
        });
      
        const finalY = doc.autoTable.previous.finalY + 10;
      
        // Section: Result Information
        doc.setFontSize(14);
        doc.setTextColor(40, 40, 40);
        doc.setFont("helvetica", "bold");
        doc.text("Result Information:", 10, finalY); // Keep the original position (x: 10)
      
        // Result Details Table
        const statusMapping = {
          1: "Undefined",
          2: "Exploring",
          3: "Early Implementation",
          4: "Conscious Pursuit",
          5: "Excelling",
        };
      
        const resultDetails = [
          [result.subject1Name || "N/A", result.subject1Grade || "N/A", statusMapping[result.subject1status] || "N/A"],
          [result.subject2Name || "N/A", result.subject2Grade || "N/A", statusMapping[result.subject2status] || "N/A"],
          [result.subject3Name || "N/A", result.subject3Grade || "N/A", statusMapping[result.subject3status] || "N/A"],
          [result.subject4Name || "N/A", result.subject4Grade || "N/A", statusMapping[result.subject4status] || "N/A"],
          [result.subject5Name || "N/A", result.subject5Grade || "N/A", statusMapping[result.subject5status] || "N/A"],
        ];
      
        doc.autoTable({
          startY: finalY + 5,
          head: [["Subject Name", "Grade", "Status"]],
          body: resultDetails,
          theme: "grid",
          headStyles: {
            fillColor: [0, 102, 204],
            textColor: [255, 255, 255],
            fontSize: 10,
            halign: "center", // Center table headers
            fontStyle: "bold",
          },
          bodyStyles: {
            fontSize: 10,
          },
          styles: { cellPadding: 4 },
          columnStyles: {
            0: { halign: "left", fontStyle: "bold", cellWidth: 80 },
            1: { halign: "center", cellWidth: 60 },
            2: { halign: "center", cellWidth: 50 },
          },
        });
      
        const gradingKeyY = doc.autoTable.previous.finalY + 10;
      
        // Section: Grading Key
        doc.setFontSize(14);
        doc.setTextColor(40, 40, 40);
        doc.setFont("helvetica", "bold");
        doc.text("Grading Key:", 10, gradingKeyY); // Keep the original position (x: 10)
      
        const gradingKey = [
          ["A+", "A", "B+", "B", "C+"],
          ["85 and above", "78-84", "72-78", "65-72", "55-65"],
        ];
      
        doc.autoTable({
          startY: gradingKeyY + 5,
          head: [["A+", "A", "B+", "B", "C+"]],
          body: [gradingKey[1]],
          theme: "grid",
          headStyles: {
            fillColor: [0, 102, 204],
            textColor: [255, 255, 255],
            fontSize: 10,
            halign: "center", // Center table headers
            fontStyle: "bold",
          },
          bodyStyles: {
            fontSize: 10,
          },
          styles: { cellPadding: 4 },
          columnStyles: {
            0: { halign: "center", cellWidth: 36 },
            1: { halign: "center", cellWidth: 36 },
            2: { halign: "center", cellWidth: 36 },
            3: { halign: "center", cellWidth: 36 },
            4: { halign: "center", cellWidth: 36 },
          },
        });
      
        // Footer Section
        const footerY = doc.internal.pageSize.height - 20;
        doc.setFontSize(10);
        doc.setFont("helvetica", "italic");
        doc.setTextColor(150, 150, 150);
        doc.text(`Generated by ${user.institute}`, 105, footerY, { align: "center" }); // Centered Footer
      
        // Save the PDF
        doc.save("Student_Report.pdf");
      };
      

    
      useEffect(()=>{
        if (userError) {
          alert.error(userError)
          dispatch(clearErrors())
        }
    
        if (error) {
          alert.error(error);
          dispatch(clearErrors());
        }
        dispatch(getResultDetails(id));
       
      },[userError,dispatch,alert,error,id]);
   
      
  

  return (
    <Fragment>
        {
            loading ? (<Loader />) :(
                <Fragment>
                <div className="downloadResultContainer">
             
                 <div>
         
                 </div>
                 <div>
                 <div className='dashboardMainContainer'>
                           
                           <div className="bannerContainer">
                                     <div>
                                     <img src={logo} alt="logo" />
                                     </div>     
                                     <div>
                                       <b>{user && user.firstName} {user && user.lastName}</b>
                                       <p><SearchIcon/></p>
                                     </div>     
                                       </div>
                               <div className="maindashboard">
                            
                                   <div>
                                       <Sidebar />
                                   </div>
                                   <div>
                                    
                                       <div className='resultMainContainer'>
                                       <div className="resultContainer1">
                                 <div>
                                  <div>
                                  <LeaderboardIcon />
                                  </div>
                                  <div>
                                 <h3> {result && result.activityName } Remarks </h3>
                                 <p>Activity#{result && result.activityNo}</p>
                    
                                  </div>
                                 
                                 </div>     
                                 <div>
                                   <p><MoreHorizIcon/></p>
                                 </div>     
                               </div>
                        
                    
                               <div className='resultContainer2'>
                    
                                
                              
                               <div className="remarks-container">
                         
                    
                          <div className="remarks-content">
                            <table className="remarks-table">
                              <thead>
                                <tr>
                                  <th>Label</th>
                                  <th>Grades</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><span className="dot dot-blue"></span>{result && result.subject1Name}</td>
                                  <td>{result && result.subject1Grade}</td>
                                  <td>
                                    <Rate
                                      character={({ index }) => emojiIcons[index]}
                                      count={5}
                                      value={result && result.subject1status}
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td><span className="dot dot-red"></span>{result && result.subject2Name}</td>
                                  <td>{result && result.subject2Grade}</td>
                                  <td>
                                    <Rate
                                      character={({ index }) => emojiIcons[index]}
                                      count={5}
                                      value={result && result.subject2status}
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td><span className="dot dot-orange"></span>{result && result.subject3Name}</td>
                                  <td>{result && result.subject3Grade}</td>
                                  <td>
                                    <Rate
                                      character={({ index }) => emojiIcons[index]}
                                      count={5}
                                      value={result && result.subject3status}
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td><span className="dot dot-green"></span>{result && result.subject4Name}</td>
                                  <td>{result && result.subject4Grade}</td>
                                  <td>
                                    <Rate
                                      character={({ index }) => emojiIcons[index]}
                                      count={5}
                                      value={result && result.subject4status}
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td><span className="dot dot-light-orange"></span>{result && result.subject5Name}</td>
                                  <td>{result && result.subject5Grade}</td>
                                  <td>
                                    <Rate
                                      character={({ index }) => emojiIcons[index]}
                                      count={5}
                                      value={result && result.subject5status}
                                      disabled
                                    />
                                  </td>
                                </tr>
                              </tbody>
                    
                            </table>
                           <div className='downloadContainer'>
                            <button onClick={generatePDF} className="download-button">Download Now</button>
             
                            </div>        
                            <div className="remarks-footer">
                            <div>
                              <AccessTimeIcon />
                            <p>{result && String(result.createdAt).substr(0,10)}</p>
                    
                            </div>  
                           <div>
                            <ChatBubbleOutlineIcon />
                            <p>3</p>
                     
                          </div>      
                          </div>
                          </div>
                    
                        
                    
                          <div className="teacher-remarks">
                            <h4>Teacher’s Remarks</h4>
                            <textarea
                              className="remarks-textarea"
                              readOnly
                              value={result && result.teacherFeedback}
                            />
                            <div className="remarks-actions">
                               <div>
                               <AccountCircleIcon />
                              <AccountCircleIcon />
                              <AccountCircleIcon />
                               </div>
                               <div>
                                
                              <button className="icon-button">📎</button>
                              <button className="icon-button">😊</button>
                               </div>
                            </div>
                          </div>
                        </div>
                           
                    
                            
                                  </div>
                              </div>
                                    
                           </div>
                         
                         
                           </div>
                            </div>
                         
                 </div>
                </div>
               </Fragment>
            )
        }
    </Fragment>
  );
};

export default DownloadResult