import React, { Fragment, useEffect } from 'react'
import Sidebar from '../Dashboard/Sidebar'
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../images/logo.png';
import { useDispatch , useSelector } from 'react-redux';
import { clearErrors , getChildrenProfilesAction } from '../../actions/parentActions';
import Loader from '../Loader/Loader';
import { useAlert } from 'react-alert';
const ChildernProfiles = () => {
  const {error:userError,user} = useSelector((state)=>state.loginUser);

  const {loading , error , parents} = useSelector((state)=>state.childrenProfiles);

  const dispatch = useDispatch();

  const alert = useAlert();


  useEffect(()=>{
    if (error) {
      alert.error(error);
      dispatch(clearErrors())
    }
    if (userError) {
      alert.error(userError);
      dispatch(clearErrors())
    }
    dispatch(getChildrenProfilesAction())
  },[dispatch,userError,alert,error])
  return (
     <Fragment>
        {
            loading ? <Loader /> :  <Fragment>
     
            <div className="dashboardMainContainer">
            <div className="bannerContainer">
                 <div>
                 <img src={logo} alt="logo" />
                 </div>     
                 <div>
                   <b>{user && user.firstName} {user && user.lastName}</b>
                   <p><SearchIcon/></p>
                 </div>     
               </div>
               <div className="maindashboard">
                   <div>
                       <Sidebar />
                   </div>
                   <div>
                   {
                    parents && parents.map((par) => (
                        <div className='miniProfileContainer'>
                        <div className='myProfile'>
         
                             <h4>{par && par.firstName} {par && par.lastName} Profile</h4>
                         </div>
                         
                         <div className="profileContainer">
                           <div className='profileContainerFirst'>
                             <img src={par.avatar && par.avatar.url} alt={user.firstName }
                             />
                             <h2> {par && par.firstName} {par && par.lastName} </h2>
                             <h3> {par && par.classTeaching} Teaching Grade</h3>
                             <p>Home Address : {par && par.address}</p>
                           </div>
                           <div className="profileContainerSecond">
                             <div className="profileContainerSecondMini">
                             <h2>Basic Information</h2>
         
                               <div>
                                 <h4>Full Name</h4>
                                 <p>{par && par.firstName} {par && par.lastName}</p>
                               </div>
                               <div>
                                 <h4>Email Address</h4>
                                 <p>{par && par.email}</p>
                               </div>
                               <div>
                                 <h4>Phone Number</h4>
                                 <p>{par && par.phoneNo}</p>
                               </div>
                               <div>
                                 <h4>Home Address</h4>
                                 <p>{par && par.address} </p>
                               </div>
                             </div>
                             <div className="profileContainerSecondMini2">
                               <h2>Other Information</h2>
                               <div>
                                 <h4>Your Qualification</h4>
                                 <p>{par && par.qualification} </p>
                               </div>
                               <div>
                                 <h4> Teaching Grade</h4>
                                 <p>{par && par.classTeaching} </p>
                               </div>
                               <div>
                                 <h4>Your Curriculum</h4>
                                 <p>{par && par.curriculumTeaching} </p>
                               </div>
                               <div>
                                 <h4>National ID/Passport Number</h4>
                                 <p>{par && par.identification} </p>
                               </div>
                               <div>
                                 <h4>Institute</h4>
                                 <p>{par && par.institute} </p>
                               </div>
                               <div>
                                 <h4>Your Gender</h4>
                                 <p>{par && par.gender} </p>
                               </div>
                           
                               
                              
                               
                             
                             </div>
                           </div>
             </div>
                     </div>
                    ))
                   }
                   <div className='secondProfileContainer'>
        
          
       
        </div>
        
                   </div>
               </div>
       
          </div>
       
       
       
           
       
       
       
           </Fragment>
        }
     </Fragment>
  )
}

export default ChildernProfiles