import React, { Fragment, useEffect } from 'react';
import './AdminPanel.css';
import logo from '../../images/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import {useSelector , useDispatch} from 'react-redux';
import { clearErrors } from '../../actions/userActions';
import AdminSidebar from './AdminSidebar';
import { Link } from 'react-router-dom';
import MetaData from '../MetaData/MetaData';
import {   allUserAction   } from '../../actions/userActions';
import { allClassLessonActions   } from '../../actions/lessonAction';
import {  allSchoolsDashboardAction } from '../../actions/dashboardActions.js';


const AdminPanel = () => {

  const {error:userError,user} = useSelector((state)=>state.loginUser);
    const {error,users} = useSelector((state)=>state.allUsers);
    const {error:dashboardError,dashboards} = useSelector((state)=>state.dashboard);
    const {error:lessonError,lesson} = useSelector((state)=>state.allLessons);

  const alert = useAlert();

  const dispatch = useDispatch(); 


  const total = users.reduce((acc) => acc + 1, 0);

  const dashboard = dashboards.reduce((acc) => acc + 1, 0);

  const les = lesson.reduce((acc) => acc + 1, 0);



  useEffect(()=>{
    if (userError) {
        alert.error(userError);
        dispatch(clearErrors());
    }
       if (error) {
          alert.error(error);
          dispatch(clearErrors());
        }
        if (dashboardError) {
          alert.error(dashboardError);
          dispatch(clearErrors());
        }
        if (lessonError) {
          alert.error(lessonError);
          dispatch(clearErrors());
        }

            dispatch(allUserAction())
            dispatch(allSchoolsDashboardAction());
            dispatch(allClassLessonActions()) 
  },[userError,alert,dispatch,error,dashboardError,lessonError])
  return (
    <Fragment>
      <MetaData title={`TLH - Admin Panel`} />
    <div className='dashboardMainContainer'>
      
  <div className="bannerContainer">
            <div>
            <img src={logo} alt="logo" />
            </div>     
            <div>
              <b>{user && user.firstName} {user && user.lastName}</b>
              <p><SearchIcon/></p>
            </div>     
              </div>
      <div className="maindashboard">
   
          <div>
              <AdminSidebar />
          </div>
          <div>
           
          <div className='dashboardContainer' >
               <div className="dashboardSummary">
                 <div>
                   <p>
                   <h1>Wellcome</h1>    Admin Panel </p>
                   </div>
                 <div className="dashboardSummaryBox2">
                   <Link>
                   <p>Member's</p>
                   <p>{total}</p>
                   </Link>
                   <Link  >
                                 <p>STEAM'S</p>
                                 <p>{dashboard}</p>
                   </Link>
                   <Link >
                   <p>Lesson's</p>
                   <p>{les}</p>
                   </Link>
                 </div>
               </div>
            
               </div>
  
          </div>
      </div>
    </div>
  
  
  
  </Fragment>
    )
}

export default AdminPanel