import { CHILDERN_PROFILES_FAIL, CHILDERN_PROFILES_REQUEST, CHILDERN_PROFILES_SUCCESS, CHILDERN_RESULTS_FAIL, CHILDERN_RESULTS_REQUEST, CHILDERN_RESULTS_SUCCESS, CLEAR_ERRORS } from "../constants/parentsConstants";



//  Get All Children results 
export const getAllChildernReducer = (state  =  {parents:[]} ,action) => {
    switch (action.type) {
        case CHILDERN_PROFILES_REQUEST:
            case CHILDERN_RESULTS_REQUEST:
            return {
                loading: true,
                parents: []
            }
        case CHILDERN_PROFILES_SUCCESS:
            case CHILDERN_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                parents: action.payload.parents
            }
        case CHILDERN_PROFILES_FAIL:
            case CHILDERN_RESULTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }     
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }       
    
        default:
            return state;
    }


}