import React, { Fragment, useEffect } from 'react';
import logo from '../../images/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import {useSelector , useDispatch} from 'react-redux';
import { clearErrors  , myClassAllMembers  } from '../../actions/userActions';
import {DataGrid} from '@material-ui/data-grid';
import Loader from '../Loader/Loader.js';
import { useNavigate } from 'react-router-dom';
import { DELETE_USER_RESET } from '../../constants/userConstants.js';
import TeacherSidebar from './TeacherSidebar.js';

const TeacherAllUsers = () => {

  const {error:userError,user} = useSelector((state)=>state.loginUser);
  const {loading,error,users} = useSelector((state)=>state.allUsers);
  const {error:deleteUser,success , message} = useSelector((state)=>state.updatePassword);



  const alert = useAlert();
  const history = useNavigate();
  const dispatch = useDispatch();

 


  const columns = [
    {
     field:"id",
     headerName:"Members ID's",
     minWidth:300,
     flex:2.5
    },
   {
    field:"name",
    headerName:"Members Names",
    minWidth:250,
    flex:1
  },
  {
    field:"createdAt",
    headerName:"Members Joining Date",
    minWidth:250,
    flex:1
  },
  {
    field:"role",
    headerName:"Members Roles",
    minWidth:250,
    flex:1
  },
    {
    field:"email",
    headerName:"Members Emails",
    minWidth:250,
    flex:1
  },
  {
    field:"phoneNo",
    headerName:"Members Phone Numbers",
    minWidth:250,
    flex:1
  }, {
    field:"identification",
    headerName:"National ID/Passport Numbers",
    minWidth:250,
    flex:1
  },
  {
    field:"qualification",
    headerName:"Members Qualifications",
    minWidth:250,
    flex:1
  },
  {
    field:"curriculumTeaching",
    headerName:"Members Curriculums",
    minWidth:250,
    flex:1
  },
  {
    field:"classTeaching",
    headerName:"Members Grades",
    minWidth:250,
    flex:1
  },
  {
    field:"institute",
    headerName:"Members Institutes",
    minWidth:250,
    flex:1
  },
  {
    field:"address",
    headerName:"Members Addresses",
    minWidth:300,
    flex:1
  },
  {
    field:"gender",
    headerName:"Members Genders",
    minWidth:250,
    flex:1
  },
  {
    field:"fatherCnic",
    headerName:"Father Cnic",
    minWidth:250,
    flex:1
  }
];

const rows = [];

users && users.map((user) =>(
    rows.push({
        id: user._id,
        name: user.firstName + " " + user.lastName,
        email: user.email,
        role: user.role,
        address: user.address,
        gender: user.gender,
        phoneNo: user.phoneNo,
        identification: user.identification,
        qualification: user.qualification,
        curriculumTeaching: user.curriculumTeaching,
        classTeaching: user.classTeaching,
        institute: user.institute,
        fatherCnic: user.fatherCnic || "N/A",
        createdAt: String(user.createdAt).substr(0,10),
    })
))
  useEffect(()=>{
    if (userError) {
        alert.error(userError);
        dispatch(clearErrors());
    }
    if (error) {
        alert.error(error);
        dispatch(clearErrors());
    }
     if (deleteUser) {
            alert.error(deleteUser);
            dispatch(clearErrors());
        }
       if (success) {
            alert.success(message);
            history(`/admin-panel`)
    
            dispatch({
                type:DELETE_USER_RESET
            })
        }
  

    dispatch(myClassAllMembers())
 
  },[userError,alert,dispatch,error,history,success,message,deleteUser])
  return (
    <Fragment>
        {
            loading ? (<Loader />) : (
                <Fragment>
                <div className='dashboardMainContainer'>
                  
              <div className="bannerContainer">
                        <div>
                        <img src={logo} alt="logo" />
                        </div>     
                        <div>
                          <b>{user && user.firstName} {user && user.lastName}</b>
                          <p><SearchIcon/></p>
                        </div>     
                          </div>
                  <div className="maindashboard">
               
                      <div>
                          <TeacherSidebar />
                      </div>
                      <div>
                      <div className="productListContainer">
                        <h1 id='productListHeading'>All Members Data Management</h1>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          pageSize={10}
                          disableSelectionOnClick
                          className='productListTable'
                          autoHeight
                        />
                      </div>
              
                      </div>
                  </div>
                </div>
              
              
              
              </Fragment>
            )
        }
    </Fragment>
            )
        }
       

export default TeacherAllUsers
